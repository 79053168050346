body {
    background: #FFFFFF;
    border-end-end-radius: 0;
    color: rgba(0, 0, 0, .87);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    height: 100vh;
}

.particles {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: -1;
}

#root {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
}

.app-intro {
    grid-column-start: 2;
}

.App {
    display: grid;
    grid-auto-rows: auto;
    grid-gap: 30px;
    grid-template-columns: 200px 700px 200px;
    justify-content: center;
    text-align: center;
}

@media screen and (max-width: 699px) {

    .App {
        grid-template-columns: auto auto auto;
    }
}

form {
    background: transparent;
    display: flex;
    flex-wrap: wrap;
    grid-column-start: 2;
    padding: 1rem;

    label {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
    }

    input[type="text"] {
        background-color: transparent;
        border: 0;
        flex-grow: 1;
        font-size: 2.5rem;
        width: 100%;
    }

    input[type="text"]:focus {
        background-color: transparent;
        outline: 0;
    }

    button {
        background-color: #1976D2;
        border-radius: 5px;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .2),
            0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12);
        box-sizing: border-box;
        color: white;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-size: 2rem;
        font-weight: 500;
        letter-spacing: .02857em;
        line-height: 1.75;
        margin-top: .5rem;
        min-width: 64px;
        padding: 6px 16px;
        text-transform: uppercase;
        transition: background-color 250ms cubic-bezier(.4, 0, .2, 1) 0ms,
            box-shadow 250ms cubic-bezier(.4, 0, .2, 1) 0ms,
            border 250ms cubic-bezier(.4, 0, .2, 1) 0ms;
        width: 100%;

        &:focus {
            outline: red auto 5px;
        }

        &:hover {
            background-color: rgb(17, 82, 147);
        }
    }
}

.input-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;
    width: 50%;
}

.color-card-container {
    border-radius: 5px;
    box-shadow: #1E1E1E 2px 2px 8px;
}

.color-display {

    .color-card-container {
        display: flex;
    }
}

.color-display,
.color-display-history {
    background: transparent;
    border-radius: 5px;
    grid-column-start: 2;
    padding: 1rem;
}

.color-display-history {

    code {
        border-bottom: 1px solid #CCCCCC;
        display: block;
    }
}

code {
    background: #DADADA;
    display: inline-flex;
    justify-content: center;
    padding: .5rem;
    text-transform: lowercase;
}

.color-card {
    align-items: center;
    display: inline-flex;
    height: 100px;
    justify-content: center;
    width: 50%;
}

.card-title {
    background: #EEEEEE;
    border-radius: 5px;
    box-shadow: #1E1E1E 2px 2px 8px;
    padding: 1rem;
    text-transform: capitalize;
}

.color-card-start {
    background: red;
    border-radius: 5px 0 0 5px;
}

.color-card-end {
    background: plum;
    border-radius: 0 5px 5px 0;
}

.color-display-history .color-card-end {
    border-radius: 0 0 5px;
}

.color-display-history .color-card-start {
    border-radius: 0 0 0 5px;
}

.App-header {
    align-items: center;
    background-color: #282C34;
    color: white;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    justify-content: center;
    min-height: 100vh;
}

.App-link {
    color: #61DAFB;
}

footer {
    display: grid;
    grid-auto-rows: auto;
    grid-gap: 30px;
    grid-template-columns: 200px 700px 200px;
    justify-content: center;
    text-align: center;
    p {
        grid-column-start: 2;
        justify-content: space-around;
    }
    .footer-links {
        a {
        margin: 0 .5rem;

        }
    }
}
